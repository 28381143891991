
import {defineComponent} from "vue";
import {marked} from 'marked';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike.min.js'
import 'prismjs/components/prism-yaml.min.js'
import 'prismjs/components/prism-aspnet.min.js'
import 'prismjs/components/prism-bash.min.js'
import 'prismjs/components/prism-sql.min.js'
import 'prismjs/components/prism-csharp.min.js'
import 'prismjs/components/prism-json.min.js'
import 'prismjs/components/prism-toml.min.js'
import 'prismjs/components/prism-ini.min.js'
import TrenzMarkdownRenderer from "@/TrenzMarkdownRenderer";

export default defineComponent({
  name: "MarkdownContent",

  props: {
    content: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      options: {
        gfm: true,
        mangle: true,
        smartypants: true,
        highlight(code: string, lang: string, callback?: (error: unknown, code?: string) => void): string | void {
          try {
            let html
            if (!lang || !Prism.languages[lang]) {
              html = code
            } else {
              html = Prism.highlight(code, Prism.languages[lang], lang)
            }

            let output = "";
            const lines = html.split(/\n/gm)
            if (lang !== 'mermaid' && lines.length > 1) {
              const digitCount = lines.length.toString().length;
              for (let i = 0; i < lines.length; i++) {
                output += `<span class="line-number token comment" style="width: ${digitCount + 1}ch">${i + 1}</span>${lines[i]}\n`;
              }
            } else {
              output = html;
            }

            if (callback) callback(null, output)
            else return output
          } catch (e) {
            if (callback) callback(e)
            else throw e
          }
        },
      } as marked.MarkedOptions & { renderer: TrenzMarkdownRenderer },
      toc: "",
    };
  },

  mounted() {
    // @ts-expect-error mermaid is globally installed
    window.mermaid.init({}, ".language-mermaid");
  },

  computed: {
    output() {
      const renderer = new TrenzMarkdownRenderer()
      const opts = Object.assign({}, this.options);
      opts.renderer = renderer;

      const output = marked(this.content, opts);

      return renderer.toc() + output;
    },
  },
})
