import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_not_found_view = _resolveComponent("not-found-view")!
  const _component_markdown_content = _resolveComponent("markdown-content")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            style: {"text-align":"center"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                indeterminate: true,
                color: "primary"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_ctx.error !== null)
      ? (_openBlock(), _createBlock(_component_not_found_view, { key: 1 }))
      : (_openBlock(), _createBlock(_component_markdown_content, {
          key: 2,
          content: _ctx.content
        }, null, 8, ["content"]))
}