
import {defineComponent} from "vue";
import MarkdownContent from "@/components/MarkdownContent.vue";
import NotFoundView from "@/views/NotFoundView.vue";

export default defineComponent({
  name: "ContentView",

  components: {
    NotFoundView,
    MarkdownContent,
  },

  props: {
    location: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      content: "",
      error: null as null | string,
    };
  },

  async beforeMount() {
    try {
      this.loading = true;
      this.content = await this.$store.dispatch('findDocumentByLocation', this.location);
    } catch (e: unknown) {
      if (typeof e === 'string') {
        this.error = e;
      } else {
        console.error(e);

        this.error = "Unfortunately, an error occurred while loading the content. Please try again later.";
      }
    } finally {
      this.loading = false;
    }
  },
})
