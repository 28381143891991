import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_nav_tree_node = _resolveComponent("nav-tree-node", true)!
  const _component_v_list_group = _resolveComponent("v-list-group")!

  return (_ctx.sortedChildren === null)
    ? (_openBlock(), _createBlock(_component_v_list_item, {
        key: 0,
        title: _ctx.displayName,
        to: _ctx.link,
        active: _ctx.active
      }, null, 8, ["title", "to", "active"]))
    : (_openBlock(), _createBlock(_component_v_list_group, { key: 1 }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_list_item, {
            title: _ctx.displayName,
            active: _ctx.active,
            style: {"flex-grow":"1"},
            onClick: ($event: any) => (_ctx.onClick(props.active, props.onClick))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _withModifiers(($event: any) => (_ctx.onExpand(props.active, props.onClick)), ["stop"]),
                icon: props.appendIcon,
                color: "transparent",
                class: _normalizeClass(props.class),
                flat: true,
                density: "compact"
              }, null, 8, ["onClick", "icon", "class"])
            ]),
            _: 2
          }, 1032, ["title", "active", "onClick"])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedChildren, (childNode, i) => {
            return (_openBlock(), _createBlock(_component_nav_tree_node, {
              node: childNode,
              key: i
            }, null, 8, ["node"]))
          }), 128))
        ]),
        _: 1
      }))
}