
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'NotFoundView',

  params: {
    path: {
      type: String,
      required: false,
    }
  }
});
