
import {defineComponent} from 'vue'
import NavTreeNode from "@/components/NavTreeNode.vue";
import TrenzDocsLogo from "@/components/TrenzDocsLogo.vue";
import {mapGetters} from "vuex";
import * as api from "@/api";

export default defineComponent({
  name: 'App',

  inject: ['$settings'],

  components: {
    TrenzDocsLogo,
    NavTreeNode,
  },

  async beforeMount() {
    window.addEventListener('keydown', this.handleKeyDown)

    await this.$store.dispatch('loadNavTree');

    api.auth.state().then(result => {
      this.isSignedIn = result;
      this.signInButtonLoading = false;
    });
  },

  async mounted() {
    await this.$store.dispatch('applyWebAppSettings');
  },

  unmounted() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  data() {
    return {
      searchQuery: '',
      drawerOpen: true,
      searchFieldFocussed: false,
      isSignedIn: false,
      signInButtonLoading: true,
    }
  },

  watch: {
    async searchQuery(q) {
      await this.$store.dispatch('search', q);
      await this.maybeNavigateToSearch();
    },
  },

  methods: {
    async handleSearchFieldFocusin() {
      this.searchFieldFocussed = true;

      await this.maybeNavigateToSearch();
    },

    async handleSearchFieldFocusout() {
      this.searchFieldFocussed = false;
    },

    async maybeNavigateToSearch() {
      if (this.$route.name !== 'search' && this.$store.state.searchQuery.length > 0) {
        await this.$router.push({'name': 'search'})
      }
    },

    handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        if (this.searchFieldFocussed) {
          this.searchQuery = '';
        }
      } else if (e.key === '/') {
        if (!this.drawerOpen) {
          this.drawerOpen = true;
        }

        if (!this.searchFieldFocussed) {
          this.searchQuery = '';
          (this.$refs.search as {focus: () => void}).focus();
          e.preventDefault();
        }
      }
    }
  },

  computed: {
    ...mapGetters(['navTreeHasHiddenNodes']),

    breadcrumbItems() {
      if (!this.$route.params.location) {
        return [
          {
            text: this.$route.meta.title,
            to: this.$route.fullPath,
            disabled: false,
          }
        ];
      }

      let parts = (this.$route.params.location as string).split('/');
      let path = [] as string[];

      return parts.map(part => {
        path.push(part);

        return {
          text: part,
          to: '/wiki/' + path.join('/'),
          disabled: false,
        }
      })
    },

    title() {
      if (!this.$route.params.location) {
        return this.$route.meta.title;
      }

      let parts = (this.$route.params.location as string).split('/');
      return parts[parts.length - 1];
    },

    sortedNavTree() {
      // do not sort navTree directly, because it would modify the original array
      return [...Object.keys(this.$store.state.navTree.root).map(k => this.$store.state.navTree.root[k]).filter(n => n.order >= 0)].sort((a, b) => {
        return a.order - b.order;
      });
    },

    loginUrl() {
      return this.$settings.api.baseUrl + "auth/transfer?returnUrl=" + encodeURI(window.location.origin + this.$route.fullPath);
    },

    logoutUrl() {
      return this.$settings.api.baseUrl + "auth/signout?returnUrl=" + encodeURI(window.location.origin + this.$route.fullPath);
    },
  },
})
